const itfTradeFair = '/itf-trade-fair'
export const itfTradeFairList = itfTradeFair + '/fair-calander-circular/list'
export const getCircularData = itfTradeFair + '/fair-calander-circular/get-circular-data'

const itfFairCalenderInfo = '/itf-trade-fair/fair-calander-info/'
export const itfFairCalenderInfoList = itfFairCalenderInfo + 'list'
export const itfFairCalenderInfoStore = itfFairCalenderInfo + 'store'
export const itfFairCalenderInfoUpdate = itfFairCalenderInfo + 'update'
export const getCalenderItemInfo = itfFairCalenderInfo + 'get-calender-info'

const fairParticipationCalender = '/itf-trade-fair/fair-participation-circular/'
export const fairParticipationCalenderList = fairParticipationCalender + 'list'
export const fairParticipationCalenderDetails = fairParticipationCalender + 'details-info'

// Fair Participate Tab Based Form
export const fairInfoByCircularApi = itfTradeFair + '/fair-participation/fair-info'
export const fairPartStoreOneApi = itfTradeFair + '/fair-participation/store-one'
export const fairPartStoreTwoApi = itfTradeFair + '/fair-participation/store-two'
export const fairPartStoreThreeApi = itfTradeFair + '/fair-participation/store-three'
export const fairPartStoreConsentApi = itfTradeFair + '/fair-participation/store-five'
export const fairPartStoreLastApi = itfTradeFair + '/fair-participation/store-last'
export const fairPartListApi = itfTradeFair + '/fair-participation/list'
export const fairPartListPreviousDetailsApi = itfTradeFair + '/fair-participation/previous-details'
export const fairPartListDetailsApi = itfTradeFair + '/fair-participation/details'
export const fairPartPdfDetailsApi = itfTradeFair + '/fair-participation/pdf-details'
export const fairConsentPaperPdfApi = itfTradeFair + '/fair-participation/pdf-consent'
export const stallInformationDetailsApi = itfTradeFair + '/fair-participation/stall-details'

// Fair Participation Payment Apis
export const paymentOfflineApi = itfTradeFair + '/fair-participation-payment/offline'
export const paymentReceiptApi = itfTradeFair + '/fair-participation-payment/payment-receipt'
export const paymentOnlineApi = itfTradeFair + '/fair-participation-payment/online'
export const paymentUpdateStatusApi = itfTradeFair + '/fair-participation-payment/'

// stall category
export const stallCategoryList = 'itf-configuration/stall-category'

// Circular Exhibition
export const circularExhibitionList = 'itf-trade-fair/circular-exhibition/list'
export const checkApplicationList = 'itf-trade-fair/circular-exhibition/check-apply'

// Exhibit Info
const exhibitionInfo = '/itf-trade-fair/exhibit-info/'
export const exhibitionInfoList = exhibitionInfo + 'list'
export const exhibitionInfoStore = exhibitionInfo + 'store'
export const exhibitionInfoUpdate = exhibitionInfo + 'update'
export const exhibitionInfoShow = exhibitionInfo + 'show'
export const fairExhibitInfoShow = '/itf-trade-fair/exhibition-info/show'

// Visa Processing Info
const visaProcessingInfo = '/itf-trade-fair/visa-processing-info/'
export const visaProcessingInfoList = visaProcessingInfo + 'list'
export const visaProcessingInfoStoreApi = visaProcessingInfo + 'store'

// Visa Processing Info
const fairEvaluation = '/itf-trade-fair/fair-evaluation/'
export const fairEvaluationListApi = fairEvaluation + 'list'
export const fairEvaluationStoreApi = fairEvaluation + 'store'
export const fairEvaluationUpdateApi = fairEvaluation + 'update'

/** DITF APIS */

// DITF Circular of Category Wise Stall Payment
export const CategoryWiseStallPaymentList = 'ditf-trade-fair/category-wise-stall-payment/list'
export const DitfPaymentDue = 'ditf-trade-fair/category-wise-stall-payment/details'
export const DitfAppStallPaymentStore = 'ditf-trade-fair/category-wise-stall-payment/app-payment'
export const DitfOnlineAppStallPaymentStore = 'ditf-trade-fair/category-wise-stall-payment/app-online-payment'
export const DitfOnlineAppStallPaymentUpdate = 'ditf-trade-fair/category-wise-stall-payment/application-process-update'
export const DitfAppStallPaymentReceipt = 'ditf-trade-fair/category-wise-stall-payment/payment-receipt'
export const DitfAllotmentLetterPdf = 'ditf-trade-fair/category-wise-stall-payment/allotment-letter-pdf'

// Ditf Payment Details
export const DitfPaymentDetails = 'ditf-trade-fair/payment/details'
export const DitfPaymentStore = 'ditf-trade-fair/payment/app-payment'
export const DitfBankInformationStore = 'ditf-trade-fair/payment/bank-information'
export const DitfOnlinePaymentStore = 'ditf-trade-fair/payment/app-online-payment'
export const DitfOnlinePaymentUpdate = 'ditf-trade-fair/payment/'
export const DitfOnlinePaymentUpdateProcess = 'ditf-trade-fair/payment/application-process-update'
export const DitfPaymentReceipt = 'ditf-trade-fair/payment/receipt'

const ditfTradeFair = '/ditf-trade-fair'
// DITF Circular of Fair Participation
export const DitfCircularParticipationList = ditfTradeFair + '/participation-circular/list'
export const DitfCircularParticipationDetails = ditfTradeFair + '/participation-circular/details'
export const DitfCircularParticipationPdfDetails = ditfTradeFair + '/participation-circular/pdfDetails'

// DITF Circular of Fair Application
export const DitfApplicationList = ditfTradeFair + '/fair-application/list'
export const DitfApplicationDetails = ditfTradeFair + '/fair-application/details'

// Participation Apis routes
export const ditfCircularDetailsStoreApi = ditfTradeFair + '/fair-application/circular-details'
export const ditfTabOneStoreApi = ditfTradeFair + '/fair-application/store-tab-one'
export const ditfTabTwoStoreApi = ditfTradeFair + '/fair-application/store-tab-two'
export const ditfTabThreeStoreApi = ditfTradeFair + '/fair-application/store-tab-three'
export const ditfTabFourStoreApi = ditfTradeFair + '/fair-application/store-tab-four'
export const ditfTabLastStoreApi = ditfTradeFair + '/fair-application/store-tab-last'

export const DitfStallReport = ditfTradeFair + '/dashboard/stall/report'
// itf Dashboard Api
const itfDashBoard = 'itf-trade-fair/dashboard/'
export const itfDashBoardCircularListApi = itfDashBoard + 'list'
export const itfDashBoardUpcomingEventApi = itfDashBoard + 'event'
// DITF Refund Indo
export const RefundInfoList = 'ditf-trade-fair/refund-info/list'
